<template>
	<section class="constraints my-32">
		<div>
			<h1 class="text-center text-4xl font-bold text-black">FAQ</h1>
			<p class="mt-2 text-center text-xl text-gray-500">
				Can't find what you're looking for?
				<a
					class="underline"
					href="mailto:hello@scholarly.so"
					>Contact us</a
				>.
			</p>
		</div>
		<div class="mt-12">
			<acordian class="border-b border-gray-200">
				<template #title>
					<h1 class="text-xl font-semibold text-gray-800">How do I create flashcards from PDF?</h1>
				</template>
				<template #content>
					<p class="text-xl">
						It's actually quite easy to create flashcards from PDFs. Here's a step-by-step guide:
						<nuxt-link
							class="text-primary underline"
							target="_blank"
							to="/blog/how-to-create-flashcards-from-pdfs-updated">
							How to create flashcards from PDFs
						</nuxt-link>
						.
					</p>
				</template>
			</acordian>

			<acordian class="border-b border-gray-200">
				<template #title>
					<h1 class="text-xl font-semibold text-gray-800">Is Scholarly free to use?</h1>
				</template>
				<template #content>
					<p class="text-xl">
						Most features of Scholarly are available for free. Our premium features require a subscription, with plans starting at $8/month. Details about our
						pricing can be found
						<nuxt-link
							class="text-primary underline"
							to="/pricing">
							here
						</nuxt-link>
						.
					</p>
				</template>
			</acordian>

			<acordian class="border-b border-gray-200">
				<template #title>
					<h1 class="text-xl font-semibold text-gray-800">How can I chat with my PDFs?</h1>
				</template>
				<template #content>
					<p class="text-xl">Simply click 'Full Screen' on a PDF, or click the PDF inside your home page and you can start chatting right away.</p>
				</template>
			</acordian>

			<acordian class="border-b border-gray-200">
				<template #title>
					<h1 class="text-xl font-semibold text-gray-800">What languages does Scholarly support for AI interaction?</h1>
				</template>
				<template #content>
					<p class="text-xl">
						Scholarly supports multiple languages, allowing you to interact with the AI in your native language for a more seamless learning experience. You can
						view all support languages inside Scholarly.
					</p>
				</template>
			</acordian>

			<acordian class="border-b border-gray-200">
				<template #title>
					<h1 class="text-xl font-semibold text-gray-800">How do I use the quiz generation feature?</h1>
				</template>
				<template #content>
					<p class="text-xl">
						Simply click 'Study' on a flashcard deck and start learning. The quiz generation feature will automatically create quizzes based on the flashcards in
						the deck.
					</p>
				</template>
			</acordian>

			<acordian class="border-b border-gray-200">
				<template #title>
					<h1 class="text-xl font-semibold text-gray-800">Can I explore and use other students' notes and flashcards?</h1>
				</template>
				<template #content>
					<p class="text-xl">
						Yes, Scholarly allows you to explore and utilize notes and flashcards created by other students. This can enhance your learning experience by giving you
						access to diverse perspectives and information. Find out more about this community feature
						<nuxt-link
							class="text-primary underline"
							target="_blank"
							to="/community">
							here
						</nuxt-link>
						.
					</p>
				</template>
			</acordian>

			<acordian class="border-b border-gray-200">
				<template #title>
					<h1 class="text-xl font-semibold text-gray-800">Are there discounts for educators and educational institutions?</h1>
				</template>
				<template #content>
					<p class="text-xl">
						While there is no discount for students, educators and educational institutions can reach out to us for special pricing. Please contact us at
						<a
							class="text-primary underline"
							href="mailto:hello@scholarly.so"
							>hello@scholarly.so</a
						>
						for more details.
					</p>
				</template>
			</acordian>

			<acordian class="border-b border-gray-200">
				<template #title>
					<h1 class="text-xl font-semibold text-gray-800">What should I do if Scholarly isn't working?</h1>
				</template>
				<template #content>
					<p class="text-xl">
						If you are experiencing issues with Scholarly, please don't hesitate to contact us for assistance at
						<a
							class="text-primary underline"
							href="mailto:hello@scholarly.so"
							>hello@scholarly.so</a
						>. We are here to help ensure your experience is smooth and beneficial.
					</p>
				</template>
			</acordian>
		</div>
	</section>
</template>
