<template>
	<div>
		<div
			:class="{ 'bg-gray-100': open }"
			class="flex cursor-pointer flex-row items-center justify-between px-4 py-4 transition-all hover:bg-gray-100"
			role="button"
			@click="toggleDaThing()">
			<div>
				<slot name="title" />
			</div>
			<AnimatedChevronIcon
				:mode="open ? 'down' : 'up'"
				class="h-5 w-5 text-gray-500" />
		</div>
		<div
			ref="toggle"
			class="accordion-content overflow-hidden px-4">
			<div class="py-4">
				<slot name="content" />
			</div>
		</div>
	</div>
</template>
<script lang="ts" setup>
const open = ref(false);

const toggle = ref<HTMLElement>();

function toggleDaThing() {
	open.value = !open.value;

	if (open.value) {
		toggle.value!!.style.height = `${toggle.value!!.scrollHeight}px`;
	} else {
		toggle.value!!.style.height = "0px";
	}
}
</script>
<style scoped>
.accordion-content {
	transition: height 0.1s ease-out;

	height: 0;
	padding-top: 0;
	padding-bottom: 0;
}
</style>
